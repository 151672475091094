import React from 'react';
import useScreenRecognition from 'hooks/useScreenRecognition';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import './QuizIntro.scss';
import QuizSubtitle from './QuizSubtitle';
import { IQuizIntroProps } from './model';

const QuizIntro = (props: IQuizIntroProps) => {
  const {
    title,
    description,
    startQuiz,
    startQuizButtonText,
    startQuizButtonAriaLabel,
    additionalText,
    localQuizImage,
    mobileLocalQuizImage,
    quizImageAltText,
    topBannerDescription,
    homepageBottomImage1,
    homepageBottomImage2,
  } = props;

  const { isMobile } = useScreenRecognition();
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    localStorage.removeItem('selectedQuestionNumber');
    localStorage.removeItem('skip');
  }

  return (
    <div className="quiz-intro">
      <div className="quiz-container">
        <h1 className="quiz-title">{title}</h1>
        <QuizSubtitle className="quiz-description" subtitle={description} />
        <button
          type="button"
          aria-label={startQuizButtonAriaLabel}
          className="button button--transparent-white"
          onClick={startQuiz}
        >
          {startQuizButtonText}
        </button>
      </div>
      {topBannerDescription ? (
        <div className="quiz-intro__top-banner-container">
          <p className="quiz-intro__top-banner-description">{topBannerDescription}</p>
        </div>
      ) : null}
      <div className="quiz-intro__image">
        {isMobile ? (
          // Image for mobiles
          mobileLocalQuizImage?.childImageSharp ? (
            <GatsbyImage
              isLazyLoading={false}
              fluid={mobileLocalQuizImage.childImageSharp.fluid}
              alt={quizImageAltText}
            />
          ) : null
        ) : localQuizImage?.childImageSharp ? (
          // Image for desktop
          <GatsbyImage
            isLazyLoading={false}
            fluid={localQuizImage.childImageSharp.fluid}
            alt={quizImageAltText}
          />
        ) : null}
      </div>
      {homepageBottomImage1 && homepageBottomImage2 ? (
        <div className="quiz-intro__bottom-image-container">
          <GatsbyImage
            isLazyLoading={false}
            alt={homepageBottomImage1.altText}
            fluid={homepageBottomImage1.gatsbyImage?.childImageSharp.fluid}
          />
          <GatsbyImage
            isLazyLoading={false}
            alt={homepageBottomImage2.altText}
            fluid={homepageBottomImage2.gatsbyImage?.childImageSharp.fluid}
          />
        </div>
      ) : null}
      <div className="quiz-container">
        <div className="quiz-aditional" dangerouslySetInnerHTML={{ __html: additionalText }} />
      </div>
    </div>
  );
};

export default QuizIntro;
