import React from 'react';
import QuizOption from './QuizOption';
import QuizQuestionSubtitle from './QuizSubtitle';
import './QuizQuestion.scss';
import { IQuestionProps } from './model';

const QuizQuestion = (props: IQuestionProps) => {
  const {
    id,
    options,
    title,
    updateQuizStatus,
    selectedProductsTags,
    selectedOptions,
    subtitle,
    tag: tagCurrentState,
  } = props;

  return (
    <div className="quiz-question">
      <h2>{title}</h2>
      <div className="quiz-question__top">
        <QuizQuestionSubtitle subtitle={subtitle} className="quiz-question__top__subtitle" />
      </div>

      {options ? (
        <ul className="quiz-question__options-container" id={id}>
          {options.map((option) => {
            const {
              id: optionId,
              title: optionTitle,
              description: optionDescription,
              icon,
              imageAltText,
              tag,
              localOptionIcon,
              singleSelection,
              nextStep,
              dependQuestion,
              products,
              dependTag,
              dependResults,
              skipQuestion,
              disableByTag,
            } = option;
            if (+disableByTag > 0 && !tagCurrentState.includes(dependTag?.[0].name)) {
              return;
            }
            const selected = selectedOptions.indexOf(tag) !== -1;
            const disabled =
              Boolean(selectedProductsTags.length) && selectedProductsTags.indexOf(tag) < 0;
            const isSingleSelection = Boolean(Number(singleSelection));

            return (
              <QuizOption
                {...{ nextStep, dependQuestion, products, dependTag, dependResults, skipQuestion }}
                key={optionId}
                title={optionTitle}
                id={tag}
                icon={icon}
                selected={selected}
                imageAltText={imageAltText}
                description={optionDescription}
                disabled={disabled}
                updateQuizStatus={updateQuizStatus}
                localOptionIcon={localOptionIcon}
                isSingleSelection={isSingleSelection}
              />
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default QuizQuestion;
