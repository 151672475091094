import React from 'react';
import classNames from 'classnames';
import './QuizControllers.scss';
import { IQuizControllersProps } from './model';

const QuizControllers = (props: IQuizControllersProps) => {
  const {
    nextButtonText,
    nextButtonAriaLabel,
    prevButtonText,
    prevButtonAriaLabel,
    getResultsButtonText,
    getResultsButtonAriaLabel,
    nextIsShown,
    nextStepIsDisabled,
    switchToNext,
    switchToPrevious,
    submitResults,
    skipQuestion,
  } = props;

  const _switchToPrevious = () => {
    switchToPrevious();
    let plus;
    skipQuestion ? (plus = skipQuestion + 1) : (plus = 1);
    localStorage.setItem(
      'selectedQuestionNumber',
      +localStorage.getItem('selectedQuestionNumber') - plus
    );
  };

  return (
    <div className="quiz-controllers">
      {nextIsShown ? (
        <button
          type="button"
          aria-label={nextButtonAriaLabel}
          className={classNames('button', 'button--transparent-white', {
            'next-disabled': nextStepIsDisabled,
          })}
          onClick={() => switchToNext()}
          disabled={nextStepIsDisabled}
        >
          {nextButtonText}
        </button>
      ) : (
        <button
          type="submit"
          aria-label={getResultsButtonAriaLabel}
          className="button button--transparent-white"
          onClick={submitResults ? () => submitResults() : undefined}
          disabled={nextStepIsDisabled}
        >
          {getResultsButtonText}
        </button>
      )}
      <button
        aria-label={prevButtonAriaLabel}
        type="button"
        className="prev-btn"
        onClick={() => _switchToPrevious()}
      >
        {prevButtonText}
      </button>
    </div>
  );
};

export default QuizControllers;
