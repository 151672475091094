import React from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import { UmbracoControls } from '@shared/types/umbraco/Controls.d';
import QuizTagIcon from './QuizTagIcon';
import { IQuizResultItemProps } from './model';
import './QuizResultItem.scss';

const QuizResultItem = (props: IQuizResultItemProps) => {
  const {
    title,
    image,
    imageAltText,
    amountCondoms,
    link,
    quizTags,
    buttonText,
    buttonAriaLabel,
    hideReadMoreButton,
  } = props;

  const resultItem = () => (
    <>
      {amountCondoms ? <div className="result-item__condoms-amount">{amountCondoms}</div> : null}
      {image?.childImageSharp && (
        <GatsbyImage isLazyLoading={false} fluid={image.childImageSharp.fluid} alt={imageAltText} />
      )}
      <strong className="result-item__title">{title}</strong>
      {quizTags ? (
        <ul className="result-item__quiz-tags">
          {quizTags.map((quizTag) => (
            <QuizTagIcon
              title={quizTag.title}
              icon={quizTag.icon}
              image={quizTag.icon.localQuizTagIcon}
              key={quizTag.title}
            />
          ))}
        </ul>
      ) : null}
    </>
  );

  return (
    <li className="result-item">
      {hideReadMoreButton !== UmbracoControls.ToggleStatuses.On ? (
        <Link className="result-item__product-info" to={link}>
          {resultItem()}
        </Link>
      ) : (
        <>{resultItem()}</>
      )}

      {link && buttonText && hideReadMoreButton !== UmbracoControls.ToggleStatuses.On ? (
        <Link
          aria-label={buttonAriaLabel}
          className="result-item__read-more  button button--dark"
          to={link}
        >
          {buttonText}
        </Link>
      ) : null}
    </li>
  );
};
export default QuizResultItem;
