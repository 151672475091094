import React from 'react';
import './QuizResults.scss';
import { UmbracoControls } from '@shared/types/umbraco/Controls.d';
import QuizResultsItemsContainer from './QuizResultsItemsContainer';
import { IQuizResultProps } from './model';

const QuizResults = (props: IQuizResultProps) => {
  const {
    products,
    relatedProducts,
    buttonText,
    buttonAriaLabel,
    retakeQuiz,
    quizProductsResults,
    relatedProductsResults,
    singleProductText,
    multipleProductsText,
    readMoreButtonText,
    readMoreButtonAriaLabel,
    hideRelatedProducts,
    hideReadMoreButton,
    prevButtonText,
  } = props;

  const goBack = () => {
    retakeQuiz();
  };

  return (
    <>
      <QuizResultsItemsContainer
        header={quizProductsResults}
        singleProductText={singleProductText}
        multipleProductsText={multipleProductsText}
        products={products}
        readMoreButtonText={readMoreButtonText}
        readMoreButtonAriaLabel={readMoreButtonAriaLabel}
        hideReadMoreButton={hideReadMoreButton}
      />
      <div className="retake-btn-holder quiz-controllers">
        <button
          aria-label={buttonAriaLabel}
          type="button"
          className="retake-quiz-button button button--white"
          onClick={retakeQuiz}
        >
          {buttonText}
        </button>
        <button
          aria-label={buttonAriaLabel}
          type="button"
          className="prev-btn--dark"
          onClick={goBack}
        >
          {prevButtonText}
        </button>
      </div>
      {relatedProductsResults?.length &&
      hideRelatedProducts !== UmbracoControls.ToggleStatuses.On ? (
        <QuizResultsItemsContainer
          header={relatedProductsResults}
          singleProductText={singleProductText}
          multipleProductsText={multipleProductsText}
          products={relatedProducts}
          readMoreButtonText={readMoreButtonText}
          readMoreButtonAriaLabel={readMoreButtonAriaLabel}
        />
      ) : null}
    </>
  );
};

export default QuizResults;
