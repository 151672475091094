import React, { useContext } from 'react';
import { removeProperties, interSectionCollection } from 'gatsby-theme-husky/src/utils';
import QuizQuestion from './QuizQuestion';
import QuizControllers from './QuizControllers';
import QuizProgressBar from './QuizProgressBar';
import './Quiz.scss';
import useQuiz from './useQuiz';
import { IUmbracoQuiz } from './model';
import { CountryContext } from '../../context/CountryContext/CountryContext';
import { ICountryContext } from '../../context/CountryContext/model';

const Quiz = (props: IUmbracoQuiz) => {
  const { currentCountry, allUmbracoQuiz, allProducts } = useContext<ICountryContext>(
    CountryContext
  );

  const {
    nextButtonText,
    nextButtonAriaLabel,
    prevButtonText,
    prevButtonAriaLabel,
    getResultsButtonText,
    getResultsButtonAriaLabel,
    submitFunction,
    quizProducts,
    backToMainPage,
  } = props;
  const currentQuestion = allUmbracoQuiz?.find((question) => question.country === currentCountry);

  const {
    presentedQuestion,
    switchToNextQuestion,
    switchToPreviousQuestion,
    nextIsShown,
    prevIsShown,
    nextStepIsDisabled,
    selectedOptions,
    allowedProductsTags,
    updateQuizDataState,
    steps,
    additionalQuizQuestionText,
    questionBackground,
    activeAnswer,
    tag,
    skipQuestion,
  } = useQuiz(currentQuestion?.questions, quizProducts);

  let numberQ;

  const numberQuestion = localStorage.getItem('selectedQuestionNumber');
  if (numberQuestion) {
    numberQ = currentQuestion?.questions[+numberQuestion + skipQuestion];
  } else {
    numberQ = presentedQuestion;
  }

  const {
    subtitle,
    id: questionId,
    title: questionTitle,
    options,
    skipButtonText = '',
    skipButtonAriaLabel = '',
  } = numberQ;

  const submit = (productShow?: any) => {
    localStorage.setItem('selectedQuestionNumber', +numberQ.questionId - 1);
    if (activeAnswer?.dependQuestion) {
      if (tag.includes(activeAnswer.dependTag?.[0].name)) {
        const productsResults = interSectionCollection(activeAnswer.dependResults, allProducts);
        const products = removeProperties(productsResults);
        submitFunction ? submitFunction(products) : null;
      } else {
        const productsResults = interSectionCollection(activeAnswer.products, allProducts);
        const products = removeProperties(productsResults);
        submitFunction ? submitFunction(products) : null;
      }
    } else {
      const productsResults = interSectionCollection(activeAnswer.products, allProducts);
      if (productShow) {
        const products = removeProperties(productShow);
        submitFunction ? submitFunction(products) : null;
      } else {
        const products = removeProperties(productsResults);
        submitFunction ? submitFunction(products) : null;
      }
    }
  };

  const switchQuestions = () => {
    if (selectedOptions.length > 0) {
      if (activeAnswer.products && !activeAnswer.nextStep && !activeAnswer.dependQuestion) {
        const productsShow: any = [];
        activeAnswer.products.forEach(({ url }) => {
          const result = allProducts.nodes.find((i) => i.url === url);
          productsShow.push(result);
        });

        return submit(productsShow);
      }

      return switchToNextQuestion(activeAnswer.skipQuestion || 0);
    }
  };

  const bgQuestionImg = questionBackground
    ? {
        backgroundImage: questionBackground,
      }
    : {};

  return (
    <div className="quiz-container">
      <div className="quiz-question__bg" style={bgQuestionImg} />
      <QuizProgressBar steps={steps} numberStep={+numberQuestion + skipQuestion} />
      <QuizQuestion
        {...{ tag }}
        options={options}
        id={questionId}
        subtitle={subtitle}
        title={questionTitle}
        selectedOptions={selectedOptions}
        selectedProductsTags={allowedProductsTags}
        skipButtonText={skipButtonText}
        skipButtonAriaLabel={skipButtonAriaLabel}
        updateQuizStatus={updateQuizDataState}
        skipStep={nextIsShown ? switchToNextQuestion : submit}
      />
      <QuizControllers
        {...{ skipQuestion }}
        nextButtonText={nextButtonText}
        nextButtonAriaLabel={nextButtonAriaLabel}
        prevButtonText={prevButtonText}
        prevButtonAriaLabel={prevButtonAriaLabel}
        getResultsButtonText={getResultsButtonText}
        getResultsButtonAriaLabel={getResultsButtonAriaLabel}
        prevIsShown={prevIsShown}
        nextIsShown={nextIsShown}
        switchToNext={switchQuestions}
        switchToPrevious={switchToPreviousQuestion}
        nextStepIsDisabled={nextStepIsDisabled}
        submitResults={submit}
        backToMainPage={backToMainPage}
        numberSteps={steps.length}
        numberLocalStorage={+numberQuestion + skipQuestion + 1}
      />
      {additionalQuizQuestionText ? (
        <p className="quiz-additional-question">{additionalQuizQuestionText}</p>
      ) : null}
    </div>
  );
};

export default Quiz;
