import React from 'react';
import classNames from 'classnames';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import { IOptionProps } from './model';
import './QuizOption.scss';

const QuizOption = (props: IOptionProps) => {
  const {
    id,
    title,
    icon,
    description,
    selected,
    updateQuizStatus,
    imageAltText,
    localOptionIcon,
    isSingleSelection,
    nextStep,
    dependQuestion,
    products,
    dependResults,
    dependTag,
    skipQuestion,
  } = props;

  return (
    <li className="option-element">
      <button
        type="button"
        onClick={() =>
          updateQuizStatus(
            id,
            !selected,
            isSingleSelection,
            {
              nextStep,
              dependQuestion,
              products,
              dependTag,
              dependResults,
              skipQuestion,
            },
            title
          )
        }
        className={classNames('quiz-option', {
          'quiz-option--selected': selected,
        })}
      >
        <span className="quiz-option__title">{title}</span>
        {icon ? (
          <div className="quiz-option__image">
            {icon.svg ? (
              <DangerouslySetInnerHtml
                className="quiz-option__image-svg"
                html={icon.svg.content}
                alt={imageAltText || ''}
              />
            ) : localOptionIcon?.childImageSharp ? (
              <GatsbyImage
                isLazyLoading={false}
                fluid={localOptionIcon.childImageSharp.fluid}
                alt={imageAltText || ''}
              />
            ) : null}
          </div>
        ) : null}
        {description ? <span className="quiz-option__description">{description}</span> : null}
      </button>
    </li>
  );
};

export default QuizOption;
