import React from 'react';
import './QuizProgressBar.scss';
import { IProgressBarProps } from './model';

const QuizProgressBar = (props: IProgressBarProps) => {
  const { steps, numberStep } = props;

  return (
    <div className="progress-bar">
      <ul className="progress-bar__steps">
        {steps?.map((step) => {
          step.value === numberStep + 1 ? (step.isAnswered = false) : (step.isAnswered = true);

          return (
            <li key={step.key} className={step.isAnswered ? 'step-element' : 'active step-element'}>
              <span className="circle">{step.value}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default QuizProgressBar;
